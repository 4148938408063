.outline-accent-icon-button{
    padding: 15px 15px 15px 15px;
    border: solid 1px #0A98FF;
    border-radius: 15px;
    background-color: transparent;
    color:#0A98FF;
    cursor: pointer;
    transition: 0.4s ease;
    svg{
        width: 25px;
        height: 25px;
    }
}

.outline-accent-icon-button:hover{
    background-color: #0A98FF;
    color:white;
}