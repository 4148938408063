body{
    font-family: Inter;
}

.px12{
    font-size: 12px;
}

.px14{
    font-size: 14px;
}

.px16{
    font-size: 16px;
}

.px18{
    font-size: 18px;
}

.px20{
    font-size: 20px;
}

.px24{
    font-size: 24px;
}

.px26{
    font-size: 26px;
}

.px28{
    font-size: 28px;
}

.px30{
    font-size: 30px;
}

.px36{
    font-size: 36px;
}

.px40{
    font-size: 48px;
}

.px55{
    font-size: 55px;
}

.px65{
    font-size: 65px;
}

.w400{
    font-weight: 400;
}

.w500{
    font-weight: 500;
}

.w600{
    font-weight: 600;
}

.w700{
    font-weight: 700;
}