.cart-bags-container{
    width: 100%;
    .action-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .cart-bags-list{
        position: relative;
        width: 100%;
        margin-top: 50px;
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr 1fr;
    }
}