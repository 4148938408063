$black:#2F3032;

.black{
    color:$black;
}

.white{
    color: white;
}

.red{
    color: #FF0022;
}

.accent{
    color:#0A98FF;
}

.grey{
    color:#8F8F8F;
}