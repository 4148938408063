footer{
    padding: 15px 20px 15px 20px;
    background-color: #2F3032;
    display: flex;
    justify-content: center;
    align-items: center;
    color:white;
    .footer-content{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding-top: 15px;
        gap:15px;
        .social{
            display: flex;
            align-items: center;
            gap: 20px;
          img{
              width: 30;
              height: 30;
          }
        }
        
    }
}