header{
    position: sticky;
    padding: 15px 20px 15px 20px;
    background-color: #2F3032;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color:white;
    .logo-container{
        display: flex;
        align-items: center;
        gap:10px;
    }
    nav{
        ul{
            display: flex;
            gap:55px;
            li{
               a{
                  font-size: 16px;
                  font-weight: 400;
                  transition: 0.3s ease;
               }
                a:active{
                    color: #0A98FF;
                }
                a:hover{
                    color: #0A98FF;
                } 
            }
        }
    }
    .user-container{
        display: flex;
        gap:20px;
        select{
            cursor: pointer;
            transition: 0.3s ease;
            color:white;
            background-color: transparent;
            border: 0;
            outline: 0;
            option{
                color:black;
            }
        }
        select:hover{
            color:#0A98FF;
        }
        .user-menu{
            position: relative;
            background-color: transparent;
            img{
                cursor:pointer;
                width: 50px;
                height: 50px;
            }
            .menu{
                padding: 20px;
                margin-left: -20px;
                margin-top: 0px;
                position: absolute;
                transform: translateX(101%);
                background-color: #2F3032;
                transition: 0.3s ease;
                z-index: 2;
                color: white;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                display: flex;
                flex-direction: column;
                gap:10px;
                li a{
                    position: relative;
                    z-index: 3;
                    transition: 0.3s ease;
                }
                li a:hover{
                    color:#0A98FF;
                }
                .red{
                    cursor: pointer;
                    color:#FF0022;
                }
            }
            
        }
        .user-menu:hover > .menu{
            transform: translateX(0%);
        }
    }
}