.bags-catalog-container{
    position: relative;
    min-height: 637.73px;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns */
    grid-gap: 20px;
    margin-bottom: 60px;
}
.bag-search-input-container{
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 30px;
  .bag-search-input{
        background-color: #e1e1e1;
        width: 20%;
        border-radius: 200px;
        padding: 15px;
        font-weight: 600;
        padding-left: 30px;
    }  
}


.hero-section{
    position: relative;
    width: 100%;
    height: 826px;
    display: flex;
    justify-content: center;
    background-color: #EEEEEE;
    margin-bottom: 65px;
    z-index: -1;
    .hero-content{
        display: grid;
        grid-template-columns: 1fr 1fr; /* Two columns */
    }
    .hero-info{
        display: flex;
        justify-content: center;
        align-items: center;
        .info{
            max-width: 617px;
            display: flex;
            flex-direction: column;
            gap:40px;
        }
    }
    .hero-image{
        max-width: 100%;
        max-height: 100%;
        img{
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
        }
    }
}