.bag-page-container{
    width: 100vw;
    background-color: #BDCED3;
        main{
            width: auto;
            max-height: 824px;
            height: 100%;
            display: flex;
           aside{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .title-container{
                display: flex;
                flex-direction: column;
                gap:20px;
                border-bottom: 1px solid #A6A6A6;
            }
            .price-container{
                display: flex;
                align-items: center;
                gap:5px;
            }
        padding: 20px;
        width: 100%;
        max-width: 400px;
        //min-width: 400px;
        background-color: white;
            .main-content{
                margin-top: 35px;
                display: flex;
                flex-direction: column;
                gap:44px;
                margin-bottom: 35px;
                .amount-selection-container{
                    border: solid 1px #D9D9D9;
                    border-radius: 20px;
                    padding: 50px 35px 50px 35px;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    align-items: center;
                    grid-gap:20px;
                    text-align: center;
                    select{
                        padding: 15px;
                        width: auto;
                        background-color: #F6F6F6;
                        border: none;
                        outline: none;
                        border-radius: 12px;
                    } 
                }
            }
        }
        .bag-image{
            width: 100%;
            max-width:100%;
            max-height:100%;
            position: relative;
                img{
                    width: 100%;
                    max-width: 100%;
                    max-height:100%;
                    object-fit: contain;
                }
            }
            .description-popup{
                position: absolute;
                top:0;
                padding: 10px 15px 10px 15px;
                border-radius: 100px;
                background-color: #0A98FF;
                cursor: pointer;
                transition: 0.3s ease;
                img{
                    position: relative;
                    width: 30px;
                    height: 40px;
                }
                .info{
                    position: relative;
                    background-color: #0A98FF;
                    border-radius: 15px;
                    color: white;
                    bottom:-30px;
                    padding: 15px;
                    transition: 0.3s ease;
                }
                .info:hover{
                    cursor: pointer;
                    background-color: #1f84cc;
                }
            }
            .description-popup:hover{
                background-color: #1f84cc;
            }
        }
        .photos-container{
            width: auto;
            display: grid;
            gap:70px;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            .selected-photo-container{
                background-color: white;
                width: 400px;
                padding: 20px;
                .selected-photo{
                    width: 100%;
                    border-radius: 32px;
                    img{
                        max-width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            } 
            .unselected-photo-container{
                background-color: white;
                border-radius: 32px;
                padding:20px;
                cursor: pointer;
                .unselected-photo{
                    height:100%;
                    img{
                        max-width: 100%;
                        height:100%;
                        object-fit: contain;
                    }
                }
            }
        }
    }

.button-action-container{
    margin-top: 30px;
    display: flex;
    align-items: center;
    gap:15px;
    margin-bottom: 25px;
}