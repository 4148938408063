.bag-item-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:40px;
    border-radius: 20px;
    padding: 20px 0px 20px 0px;
    .bag-item-content{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        .info-holder{
            background-color: #1D1D1D;
            padding: 15px;
            border-radius: 10px;
        }
        .price-holder{
            background-color: #0A98FF;
            padding: 15px;
            border-radius: 10px;  
        }
        img{
            height: 500px;
        }
    }
    .color-schema{
        display: flex;
        flex-direction: column;
        gap:10px;
        li{
            border-radius: 1000px;
            background-color: red;
            width: 40px;
            height: 40px;
        }
    }
    .rotated{
        writing-mode: vertical-lr;
        //transform: rotate(-90deg); 
    }
}