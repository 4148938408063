.account-container{
    margin-top: 65px;
    display: flex;
    flex-direction: column;
    gap:35px;
    max-width: 425px;
    width: 100%;
    margin-bottom: 65px;
    .account-main{
        display: flex;
        align-items: center;
        justify-content: center;
        gap:20px;
        .image-upload label img{
            cursor: pointer;
            width: 144px;
            height: 144px;
        }
        .image-upload>input {
            display: none;
        }
        .main-info{
            display: flex;
            flex-direction: column;
            gap:25px;
        }
    }
    form{
        display: flex;
        flex-direction: column;
        gap:20px;
        input{
            padding: 10px 20px 10px 20px;
            font-size: 20px;
            font-weight: 500;
            border-radius: 25px;
            outline: none;
            border: solid 1px #D9D9D9;
        }
        .buttons-container{
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap:10px;
        }
    }
}

.delete-button{
    width: auto;
    padding: 20px 0px 20px 0px;
    border: solid 1px #FF0022;
    border-radius: 35px;
    background-color: transparent;
    font-weight: 500;
    color:#FF0022;
    font-size: 20px;

    cursor: pointer;
    
    transition: 0.4s ease;
}

.delete-button:hover{
    background-color: #FF0022;
    color:white;
}