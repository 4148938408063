.proceed-long-button{
    width: 100%;
    max-width: 280px;
    padding: 20px 80px 20px 80px;
    background-color: #1D1D1D;
    border-radius: 10px;
    filter: drop-shadow(4px 4px 19px rgba(0, 0, 0, 0.25));
    border:0px;
    outline:0;
    transition: 0.5s ease;
    
}
.proceed-long-button:hover{
    cursor:pointer;
    background-color: #2e2e2e;
}

