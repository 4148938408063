.auth-page-full-width{
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    .auth-page-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        padding: 60px 100px 60px 100px;
        border-radius: 50px;
        .buttons-container{
            width: 100%;
            padding-top: 65px;
            display: flex;
            flex-direction: column;
            gap:30px;
            padding-bottom: 65px;
        }
        input{
            padding: 20px 20px 20px 20px;
            border:solid 2px #ECECEC;
            background-color: transparent;
            border-radius: 20px;
            color: black;
            font-size: 20px;
            font-weight: 500;
        }
    }
}