.users-list-item-container{
    display: flex;
    align-items: center;
    padding: 15px 0px 15px 0px;
    gap:10px;
    transition: 0.3s ease;
    img{
      width: 50px;
      height: 50px;
      border-radius: 1000px;
    } 
    .text-holder{
      display: flex;
      flex-direction: column;
      gap:5px;
    }
}

.users-list-item-container:hover{
  cursor: pointer;
  background-color: #EEEEEE;
}